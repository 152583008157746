import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link } from 'gatsby'
import Hider from '../components/utils/Hider'
import Layout from '../layouts'
import AccessCheck from '../components/attendance/accessCheck'
import { GlobalStateContext } from '../context/GlobalContextProvider'
import { Typography, Box } from '@mui/material'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined'
import BluetoothIcon from '@mui/icons-material/Bluetooth'
import TouchAppIcon from '@mui/icons-material/TouchApp'
import MotionPhotosAutoIcon from '@mui/icons-material/MotionPhotosAuto'
import PanToolIcon from '@mui/icons-material/PanTool'
import WifiIcon from '@mui/icons-material/Wifi'
import ComputerRoundedIcon from '@mui/icons-material/ComputerRounded'
import MUIDataTable from 'mui-datatables'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Button } from '@mui/material'

const style = {
  color: '#ffffff',
  fontSize: '30px',
  padding: '5px',
  borderRadius: '100%',
}

const StatusView = (status) => {
  switch (status.type) {
    case 1:
    case 6:
    case 7:
    case 14:
    case 16:
      return (
        <LockOutlinedIcon sx={{ ...style, backgroundColor: 'error.light' }} />
      )
    case 2:
    case 8:
    case 15:
    case 17:
      return (
        <LockOpenOutlinedIcon
          sx={{ ...style, backgroundColor: 'primary.light' }}
        />
      )
    case 18:
    case 19:
    case 20:
      return (
        <TouchAppIcon sx={{ ...style, backgroundColor: 'primary.light' }} />
      )

    default:
      return ''
  }
}

const ManualView = (manual) => {
  switch (manual.type) {
    case 6:
      return (
        <Typography color="info.light" variant="h5">
          AUTO
        </Typography>
      )
    case 7:
      return (
        <Typography color="info.light" variant="h5">
          手動施錠
        </Typography>
      )
    case 8:
      return (
        <Typography color="info.light" variant="h5">
          手動解錠
        </Typography>
      )
    case 18:
      return (
        <Typography color="info.main" variant="h5">
          Bluetooth Click
        </Typography>
      )
    case 19:
      return (
        <Typography color="info.main" variant="h5">
          Wifi Click
        </Typography>
      )
    case 20:
      return (
        <Typography color="info.main" variant="h5">
          Web Click
        </Typography>
      )
    default:
      return ''
  }
}

const ViaView = (via) => {
  switch (via.type) {
    case 1:
    case 2:
    case 18:
      return (
        <BluetoothIcon
          sx={{
            color: (theme) => theme.palette.info.light,
          }}
        />
      )
    case 6:
      return (
        <MotionPhotosAutoIcon
          sx={{
            color: (theme) => theme.palette.info.light,
          }}
        />
      )
    case 7:
    case 8:
      return (
        <PanToolIcon
          sx={{
            color: (theme) => theme.palette.info.light,
          }}
        />
      )
    case 14:
    case 15:
    case 19:
      return (
        <WifiIcon
          sx={{
            color: (theme) => theme.palette.info.light,
          }}
        />
      )
    case 16:
    case 17:
    case 20:
      return (
        <ComputerRoundedIcon
          sx={{
            color: (theme) => theme.palette.info.light,
          }}
        />
      )
    default:
      return ''
  }
}

const History = ({ location }) => {
  const { gCompanyHistory, gLoginEmployee, gStripe } = useContext(
    GlobalStateContext,
  )
  const tableEl = useRef(null)
  const getMuiTheme = () =>
    createTheme({
      palette: {
        primary: {
          main: '#28aeb1',
        },
        info: {
          light: '#cccccc',
          main: '#666666',
        },
        error: {
          light: '#db807c',
          main: '#CC4A44',
        },
      },
      typography: {
        fontFamily: "'Noto Sans JP', sans-serif",
        h2: {
          fontSize: '22px',
          fontWeight: 'bold',
        },
        h4: {
          fontSize: '16px',
        },
        h5: {
          fontSize: '14px',
        },
        button: {
          textTransform: 'none',
        },
      },
      components: {
        MuiTypography: {
          styleOverrides: {
            root: {
              letterSpacing: '0.02rem',
            },
          },
        },
        MuiTableCell: {
          styleOverrides: {
            root: {
              filter:
                gStripe.subscription.items.data[0].plan.id ===
                'price_1LkMnTG8znjDYwn9HXn1ytpU'
                  ? 'blur(5px)'
                  : 'unset',
            },
          },
        },
      },
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            // backgroundColor: 'yellow',
          },
        },
      },
    })

  const handleGet = () => {

  //  console.log("handleGet")
    // console.log('捲軸套件加載的歷史')
 /*   printProperty()
    // console.log(
    //   '捲軸到底了，timestamp是=>',
    //   // gCompanyHistory.timetag,
    //   // '\n',
    //   // '觸發下一次請求',
    //   // '\n',
    //   // '捲出去的高度 =>',
    //   // '\n',
    //   // tableEl.current.offsetTop,
    // )
    if (gCompanyHistory.history.length > 0) {
      gCompanyHistory.handleGetHistory(
        gCompanyHistory.history.slice(-1)[0].timestamp,
        gCompanyHistory.companyDevices,
        getMore(),
      )
    }*/
  }

  const getMore = () => {
   // console.log("getMore")
    // console.log('頁面自動加載的歷史')
 /*   const windowHeight = document.documentElement.offsetHeight
    let tableBottom = tableEl.current.tableRef.getBoundingClientRect().bottom

    // 當頁面開始產生卷軸，則改用infinite scroll觸發加載歷史
    if (window.scrollY >= 0) {
      // console.log(
      //   '捲出去了，頁面自動加載停止，改由infinite scroll觸發 =>',
      //   'tableBottom',
      //   tableBottom,
      // )
      return
    }

    //若資料底部與視窗底部有距離，則自動加載更多歷史
    if (windowHeight - tableBottom > 0) {
      gCompanyHistory.handleGetHistory(
        gCompanyHistory.history[gCompanyHistory.history.length - 1].timestamp,
        gCompanyHistory.companyDevices,
        getMore(),
      )
    } else {
      return
    }*/
  }

  //滑到底時ScrollTop+Window會等於Body的高度。
  const printProperty = () => {
    console.log(
      'mui datatable最後一筆資料與畫面底部距離 =>',
      '\n',
      tableEl.current.tableRef.clientHeight,
      '\n',
      '表格上方到視窗頂端的距離 =>',
      tableEl.current.tableRef.offsetHeight,
      '\n',
      'window可視範圍高度 =>',
      window.pageYOffset,
      '\n',
      'body高度 =>',
      document.documentElement.offsetHeight,
    )
  }
  useEffect(() => {
    // 剛進頁面的抓取歷史
    if (gLoginEmployee.loginInfo.access.length > 0) {
      gCompanyHistory.setHistory([])
      //先抓到全公司設備，在抓全體履歷
      gCompanyHistory.handelGetDevices(
        gLoginEmployee.loginInfo.companyID,
        getMore(),
      )
    }
  }, [gLoginEmployee.loginInfo.access])
  return (
    <Layout location={location}>
      {/* <Button onClick={printProperty} variant="outlined">
        印出所有數據
      </Button> */}
      <AccessCheck access="全体履歴">
        <Hider show={gLoginEmployee.loginInfo.access.includes('全体履歴')}>
          <ThemeProvider theme={getMuiTheme()}>
            <InfiniteScroll
              dataLength={gCompanyHistory.history.length}
              next={handleGet}
              hasMore={true}
              scrollableTarget="scrollableDiv"
              // loader={<Loader />}
              // endMessage={<EndMsg />}
            >
              <MUIDataTable
                ref={tableEl}
                title={<Typography variant="h2">全体履歴</Typography>}
                columns={[
                  {
                    name: '',
                    label: '状態',
                    options: {
                      filter: false,
                      customBodyRenderLite: (dataIndex) => {
                        return (
                          <StatusView
                            type={gCompanyHistory.history[dataIndex].type}
                          />
                        )
                      },
                    },
                  },
                  {
                    name: 'timestamp',
                    label: '時間',
                    options: {
                      filter: false,
                      customBodyRenderLite: (dataIndex) => {
                        return (
                          <Typography variant="h5">
                            {new Date(
                              gCompanyHistory.history[dataIndex].timestamp,
                            ).toLocaleString('ja-JP', {
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric',
                            })}{' '}
                            {new Date(
                              gCompanyHistory.history[dataIndex].timestamp,
                            ).toLocaleTimeString('ja-JP', { hour12: true })}
                          </Typography>
                        )
                      },
                    },
                  },
                  { name: 'deviceName', label: 'デバイス名' },
                  {
                    name: 'history_tag',
                    label: 'ユーザー名',
                    options: {
                      filter: false,
                      customBodyRender: (value, tableMeta, updateValue) => {
                        const rowId = tableMeta.rowIndex
                        return (
                          <Hider show={value}>
                            <Typography variant="h5">{value}</Typography>
                            <ManualView
                              type={gCompanyHistory.history[rowId].type}
                            />
                          </Hider>
                        )
                      },
                    },
                  },
                  {
                    name: 'Via',
                    label: '操作方法',
                    options: {
                      filter: false,
                      customBodyRenderLite: (dataIndex) => {
                        return (
                          <ViaView
                            type={gCompanyHistory.history[dataIndex].type}
                          />
                        )
                      },
                    },
                  },
                ]}
                data={gCompanyHistory.history}
                options={{
                  setTableProps: () => {
                    return {
                      fullWidth: true,
                      size: 'small',
                    }
                  },
                  responsive: 'standard',
                  filter: false,
                  search: false,
                  print: false,
                  download: false,
                  viewColumns: false,
                  customToolbar: null,
                  pagination: false,
                  selectableRows: 'none',
                  elevation: 0,
                  textLabels: {
                    body: {
                      noMatch: '検索結果は０件です',
                      toolTip: 'ソート',
                      columnHeaderTooltip: (column) => `ソート ${column.label}`,
                    },
                    toolbar: {
                      search: '検索',
                      filterTable: 'フィルター',
                    },
                    filter: {
                      all: '全て',
                      title: 'フィルター',
                      reset: 'リセット',
                    },
                  },
                }}
              />
            </InfiniteScroll>
            {/* </Hider> */}
          </ThemeProvider>
        </Hider>
        <Hider
          show={
            gStripe.subscription.items.data[0].plan.id ===
            'price_1LkMnTG8znjDYwn9HXn1ytpU'
          }
        >
          <Box
            sx={{
              width: '350px',
              height: '150px',
              p: 4,
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: (theme) => theme.zIndex.drawer + 1,
              background: 'white',
              textAlign: 'center',
            }}
          >
            全体履歴を閲覧するには、設定ページにて『PRO』または『BUSINESS』プランへのアップグレードが必要です。
            <Typography
              component={Link}
              to="/settings"
              color="primary.main"
              sx={{ textDecoration: 'underline', fontWeight: 'bold' }}
            >
              設定ページはこちら
            </Typography>
          </Box>
        </Hider>
      </AccessCheck>
    </Layout>
  )
}
export default History
